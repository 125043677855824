import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment instagramFeedFragment on WpPage_Flexlayouts_FlexibleLayouts_InstagramFeed {
    sectionHeading
    headingType
  }
`

const InstagramFeed = ({ sectionHeading, headingType }) => {
  const instergramFeedQuery = graphql`
    query myQuery {
      allInstagramContent(sort: { fields: timestamp, order: DESC }, limit: 8) {
        edges {
          node {
            permalink
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 200, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  `

  const data = useStaticQuery(instergramFeedQuery)
  return (
    <>
      <Container className="my-5">
        <SectionHeading type={headingType} text={sectionHeading} />
        <Row className="mb-3 mt-5">
          {data.allInstagramContent.edges.map((item, i) => {
            return (
              <Col
                xs={6}
                sm={4}
                md={3}
                className="d-flex justify-content-between mb-3"
                key={i}>
                <a
                  href={item.node.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex="0">
                  <GatsbyImage alt="instagram" image={getImage(item.node.localFile)} className="w-100"/>
                </a>
              </Col>
            )
          })}
        </Row>
      </Container>
    </>
  )
}

export default InstagramFeed